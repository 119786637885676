
import React from 'react';

import Hero from '../components/Hero.js';
import ImagesGroup from '../components/ImagesGroup.js'


const Home = () => {
    return (
        <>
        
            <Hero />
            <ImagesGroup />
       </>
      
    );
};

export default Home;