
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const Hero = () => {
    return (
        <div className='hero-container'>
            <Container>
                <Row>
                    <Col>
                    <div className='hero-text-overlay'>
                        <h1>An herbal apothecary full of magic.</h1>
                    </div>
                        
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};

export default Hero; 