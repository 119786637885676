
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const linkStyle = {
    // textDecoration: 'none',
    // fontFamily: 'EB Garamond',
    color: 'black',
    textDecorationLine: 'underline',
    textDecorationStyle: 'bold',
    textDecorationThickness: '2px',
    textUnderlineOffset: '5px',
    textDecorationColor: 'rgb(249, 203, 85)'
}

const About = () => {
    return (
        <div className='about-container'>
            <Container className='about-heroText-Container'>
                <h2 className='about-heroText'>Hello.</h2>
            </Container>
            <Container className='about-container'>
                <p className='about-paragraph'>
                  I am inspired by local flora so I use them in my products as often as possible. These plants are often overlooked in daily care.  When treated well they can create a joyful and enchanting experience.
                </p>
                <p className='about-paragraph'>
                  Herbs of Smith products are intentionally crafted and encouraged from magic. The entire line is hand made to serve a positive purpose for self-care and rituals. 

                </p>
                <p className='about-paragraph'>
                  I hope you enjoy and please let us know if you have any <Link 
                    to='/contact'
                    style={linkStyle}
                    >
                    questions.
                 </Link>
                </p>
                <p className='about-paragraph'>
                  -Danielle & Herbs of Smith
                </p>

                <p className='about-paragraph'>
                  
                </p>
            </Container>
            <Container className='about-image-container'>
                <Image
                    src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699744721/Herbs%20Of%20Smith/images_for_development/0-1_ykxpi2.jpg'
                    className='about-image'
                >

                </Image>
            </Container>
        </div>
    );
};
export default About;