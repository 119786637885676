
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const linkStyle = {
    // textDecoration: 'none',
    // fontFamily: 'EB Garamond',
    color: 'black',
    textDecorationLine: 'underline',
    textDecorationStyle: 'bold',
    textDecorationThickness: '2px',
    textUnderlineOffset: '5px',
    textDecorationColor: 'rgb(249, 203, 85)'
}


const Stockists = () => {
    return (
        <>
            <Container className='stockists-container'>

                <br></br>
                <h2 className='stockists-blurb'>We are currently stocked at Atticus Coffee & Gifts and Petunia & Loomis in Spokane, Washington.</h2>

                <h2 className='stockists-blurb'>
                    If you would like to carry our products in your retail shop please contact us


                <Link
                    to='/contact'
                    style={linkStyle}> here.</Link>
                </h2>

            </Container>
        </>
    );
};
export default Stockists;