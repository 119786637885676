
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Container } from 'react-bootstrap';

function ContactForm() {

    const [state, handleSubmit] = useForm("xoqzgnqp");

    if (state.succeeded) {
        console.log('success ran: ', state.succeeded)
        return <Container className='confirm-form-submitted-container'>
            <p className='confirm-form-submitted-paragraph'>
              Thank you for your inquiry! We'll be in touch right away.
            </p>
        </Container>
        
    }


 return (
    <div className='form-container'>
    <form onSubmit={handleSubmit}>


      <label htmlFor="email">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />

      <label htmlFor="text">
        Message
      </label>
      <textarea
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />


      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
    </div>
 )
}

export default ContactForm;