
import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Header = () => {
    return (
        <>
        <Navbar
            bg='light'
            variant='light'
            // style={{color: 'yellow'}}
            expand='lg'
            collapseOnSelect
            >
        
            <Container>
                <LinkContainer to='/'>
                   
                    <Nav.Link 
                    className='nav-link-text'
                    style={{ fontStyle: 'bold'}}
                    >
                            Herbs of Smith
                        </Nav.Link>
                   
                </LinkContainer>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <LinkContainer to='/about'>
                        <Nav.Link className='nav-link-text'>
                            About Us
                        </Nav.Link>
                    </LinkContainer>

                    <LinkContainer to='/stockists'>
                        <Nav.Link className='nav-link-text'>
                            Stockists
                        </Nav.Link>
                    </LinkContainer>


                    </Nav>
              </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
      
    );
};

export default Header; 