
import React from 'react';
import { Container, Image, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ImagesGroup = () => {
    return (
        <Container className='images-group-container'>

            <Row>
                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699728726/Herbs%20Of%20Smith/images_for_development/gothic-goddess_touqsv.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Bath Potions
                    </Card.Text>
                </Card>
                </Col>

                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699728919/Herbs%20Of%20Smith/images_for_development/beauty-rituals_rr97nq.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Beauty Rituals
                    </Card.Text>
                </Card>
                </Col>
            </Row>

            <br></br>

              <Row>
                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699728919/Herbs%20Of%20Smith/images_for_development/witch-kit_j8xlv6.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Provisions & Accessories 
                    </Card.Text>
                </Card>
                </Col>

                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699728850/Herbs%20Of%20Smith/images_for_development/seasonal-witch-candles-flame_egxwgw.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Witch Candles
                    </Card.Text>
                </Card>
                </Col>
            </Row>

            <br></br>

              <Row>
                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699728726/Herbs%20Of%20Smith/images_for_development/stovetop-potpourri_nbejuv.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Simmer Potions
                    </Card.Text>
                </Card>
                </Col>

                <Col>
                <Card
                    className='my-3 p-0 rounded'
                    // style={{ width: '25rem' }}
                    // bg='light'
                    border='light'
                    shadow p-3 mb-5 bg-white rounded>
                    <Link to='https://www.etsy.com/shop/HerbsofSmith?ref=shop_sugg_market'>
                        <Card.Img 
                        src='https://res.cloudinary.com/dxov7pk4a/image/upload/v1699743414/Herbs%20Of%20Smith/images_for_development/28293623-3A9E-4235-A65F-5E7D3691E2EA_1_105_c_mdxntc.jpg' 
                        alt='product display'
                        variant='top'
                        />
                    </Link>
                    <Card.Text 
                    style={{ 
                        color: 'black', 
                        textAlign: 'center',
                        marginTop: '20px'
                        }}>
                        Loose Incense 
                    </Card.Text>
                </Card>
                </Col>
            </Row>
           
        </Container>
    );
};
export default ImagesGroup;