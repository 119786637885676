
import './index.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages 
import Home from './pages/Home.js';
import Stockists from './pages/Stockists.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';

// components
import Header from './components/Header.js';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route index element={<Home />}/>
        <Route path='/stockists' element={<Stockists />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/contact' element={<Contact />}/>
      </Routes>
     
    </div>
  );
}

export default App;