
import React from 'react';
import { Container } from 'react-bootstrap';
import ContactForm from '../components/ContactForm.js';

const Contact = () => {
    return (
        <>
        <Container className='contact-container'>
            <ContactForm />
        </Container>
        </>
    );
};

export default Contact;